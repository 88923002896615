import React from "react";
// import ContactForm from "../ContactFrom/ContactForm";
// import icon1 from "../../images/icons/icon_map_mark_2.svg";
// import icon2 from "../../images/icons/icon_calling_2.svg";
// import icon3 from "../../images/icons/icon_mail_3.svg";
// import icon4 from "../../images/icons/icon_calendar_2.svg";
// import icon5 from "../../images/actual/factory.png";
import img1 from "../../images/actual/contact/1.png";
import img2 from "../../images/actual/contact/2.png";
import img3 from "../../images/actual/contact/3.png";

const ContactSection = (props) => {
  return (
    <section className="contact_section section_space bg-light">
      <div className="container">
        <div className="contact_info_box row justify-content-lg-center">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="iconbox_block text-center"
              style={{
                backgroundImage: `url(${img1})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div className="iconbox_icon">
                <img src={icon1} alt="" />
              </div> */}
              <div
                className="iconbox_content"
                style={{
                  marginTop: "125px",
                }}
              >
                <h3 className="iconbox_title" style={{ color: "white" }}>
                  Head Office
                </h3>
                <p className="mb-0" style={{ color: "white" }}>
                  1st Floor Q2, Cyber Towers, Hitech city, Hyderabad, Telangana.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="iconbox_block text-center"
              style={{
                backgroundImage: `url(${img3})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div className="iconbox_icon">
                <img src={icon5} alt="" />
              </div> */}
              <div
                className="iconbox_content"
                style={{
                  marginTop: "125px",
                }}
              >
                <h3 className="iconbox_title" style={{ color: "white" }}>
                  Manufacturing Plant
                </h3>
                <p className="mb-0" style={{ color: "white" }}>
                  Denduluru Rd, Eluru,
                  <br /> Andhra Pradesh
                </p>
                <br />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon2} alt="Calling SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Contact</h3>
                <p className="mb-0">+91 9299998799</p>
                <br />
                <br />
              </div>
            </div>
          </div> */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="iconbox_block text-center"
              style={{
                backgroundImage: `url(${img2})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div className="iconbox_icon">
                <img src={icon3} alt="" />
              </div> */}
              <div
                className="iconbox_content"
                style={{
                  marginTop: "125px",
                }}
              >
                <h3 className="iconbox_title" style={{ color: "white" }}>
                  Email
                </h3>
                <a
                  href="mailto:info@saansopharma.in"
                  className="mb-0"
                  style={{ color: "white", fontSize: "1.1rem" }}
                >
                  info@saansopharma.in
                </a>
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="iconbox_block text-center">
              <div className="iconbox_icon">
                <img src={icon4} alt="Calendar SVG Icon" />
              </div>
              <div className="iconbox_content">
                <h3 className="iconbox_title">Visit Between</h3>
                <p className="mb-0">Mon - Sat: 8.00-5.00</p>
                <p className="mb-0">Sunday: Closed</p>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="section_space pb-0">
          <div className="row justify-content-lg-center">
            <div className="col-lg-7">
              <div className="contact_form mb-0">
                <h3 className="details_item_info_title mb-1">
                  Send Us A Message
                </h3>
                <p className="mb-5">
                  Give us chance to serve and bring magic to your brand.
                </p>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="gmap_canvas ps-lg-5">
                <iframe
                  title="map"
                  src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default ContactSection;
