import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/software-company-components/Footer/Footer";
import rd from "../../images/backgrounds/common-banner.gif";
import s1 from "../../images/actual/r&d/capsul.png";
import s2 from "../../images/actual/r&d/R&d -1.png";
import s3 from "../../images/actual/r&d/contrast.png";
import s4 from "../../images/actual/r&d/inhale.png";

import "./randd.css";

const ResearchAndDevelopment = () => {
  return (
    <Fragment>
      <div>
        <Header2 />
        <div
          style={{
            //   width: "100%",
            //   height: "100vh",
            backgroundImage: `url(${rd})`,
          }}
        >
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "23vh",
              paddingBottom: "23vh",
              color: "black",
            }}
          >
            Research & Development
          </h1>
        </div>
        <div
          style={{ paddingTop: "75px", paddingBottom: "75px" }}
          className="scroll-animation"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p style={{ fontSize: "1.25rem" }}>
                  Saanso has one of the best Research and Development
                  infrastructures combining development excellence and
                  manufacturing science, which forms the backbone of our
                  manufacturing operations. The Research and Development team
                  possesses wide-ranging expertise in formulation development,
                  analytical research and niche segment development. Our
                  comprehensive and integrated development services are equipped
                  with the best technology to augment its growth path to develop
                  highest quality formulations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <div
              className="col-lg-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>EXPLORING OUTER SPACE, ONE MISSION AT A TIME</h1>
            </div> */}
            <div className="col-lg-4 ">
              <div className="image-container ">
                <img src={s2} alt="" />
              </div>

              <br />
              <div className="image-container ">
                <img src={s4} alt="" style={{ marginTop: "20px" }} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="image-container ">
                <img src={s1} alt="" />
              </div>
              <br />
              <div className="image-container ">
                <img src={s3} alt="" style={{ marginTop: "20px" }} />
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <Scrollbar />
      </div>
    </Fragment>
  );
};
export default ResearchAndDevelopment;
