import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/software-company-components/Footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import ContactSection from "../../components/ContactSection";
import commonbanner from "../../images/backgrounds/common-banner.gif";

const ContactPage = (props) => {
  return (
    <Fragment>
      <Header2 />

      {/* <PageTitle
          pageTitle={"Contact Us"}
          pagesub={"Us"}
          pageTop={"Contact"}
        /> */}
      <section style={{ backgroundImage: `url(${commonbanner})` }}>
        <div className="container">
          <div
            style={{
              height: "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Contact US</h1>
          </div>
        </div>
      </section>
      <ContactSection />
      {/* <CtaSection /> */}

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;
