import sImg1 from "../images/Category/1.png";
import sImg2 from "../images/Category/2.png";
import sImg3 from "../images/Category/3.png";
import sImg4 from "../images/Category/4.png";
const Services = [
  {
    Id: "1",
    sImg: sImg1,
    title: "Neuro Sciences",
    slug: "Neuro Sciences",
    thumb1: "Neuro Sciences",
    thumb2: "Neuro Sciences",
    col: "col-lg-6",
    description: " ",
    link: "neuro",
  },
  {
    Id: "2",
    sImg: sImg3,
    title: "Cardiovascular Sciences",
    slug: "Cardiovascular Sciences",
    thumb1: "Cardiovascular Sciences",
    thumb2: "Cardiovascular Sciences",
    col: "col-lg-6",
    description: " ",
    link: "cardio",
  },
  {
    Id: "3",
    sImg: sImg2,
    title: "Respiro Sciences",
    slug: "Respiro Sciences",
    thumb1: "Respiro Sciences",
    thumb2: "Respiro Sciences",
    col: "col-lg-6",
    description: " ",
    link: "respiro",
  },
  {
    Id: "4",
    sImg: sImg4,
    title: "General Segment",
    slug: "General Segment",
    thumb1: "General Segment",
    thumb2: "General Segment",
    col: "col-lg-6",
    description: "",
    link: "pharma",
  },
  //   {
  //     Id: "5",
  //     sImg: sImg5,
  //     title: "UI/UX Design Services",
  //     slug: "UI-UX-Design-Services",
  //     thumb1: "Website",
  //     thumb2: "Mobile App",
  //     col: "col-lg-4",
  //     description:
  //       "Expert Guidance for a Seamless Immigration Journey Expert Guidance...",
  //   },
  //   {
  //     Id: "6",
  //     sImg: icon1,
  //     title: "Custom Software Development",
  //     slug: "Custom Software Development",
  //     features: [
  //       "Software architecture design",
  //       "System integration services",
  //       "Data migration services",
  //       "Legacy app modernization",
  //     ],
  //   },
  //   {
  //     Id: "7",
  //     sImg: icon2,
  //     title: "Audit & IT Consulting Services",
  //     slug: "Audit-&-IT-Consulting-Services",
  //     features: [
  //       "TechGuard Audit",
  //       "CyberSafe Audit & IT Consulting",
  //       "AssuranceEdge & IT Consulting",
  //       "IT Sentry Audit & IT Consulting",
  //     ],
  //   },
  //   {
  //     Id: "8",
  //     sImg: icon3,
  //     title: "Web Application Design and Development",
  //     slug: "Web-Application-Design-and-Development",
  //     features: [
  //       "Web app development services",
  //       "Web portal development services",
  //       "Website development services",
  //       "Offshore web development",
  //     ],
  //   },
  //   {
  //     Id: "9",
  //     sImg: icon4,
  //     title: "Mobile App Design and Development",
  //     slug: "Mobile-App-Design-and-Development",
  //     features: [
  //       "Android development services",
  //       "iOS app development services",
  //       "Mobile application design services",
  //       "Enterprise mobile app development",
  //     ],
  //   },
  //   {
  //     Id: "10",
  //     sImg: icon5,
  //     title: "Best UI/UX Design Services",
  //     slug: "Best-UI/UX-Design-Services",
  //     features: [
  //       "PixelPerfection UI/UX Design",
  //       "DesignCraft UI/UX Design",
  //       "CreativeWave UI/UX Design",
  //       "InterfaceGenius UI/UX Design",
  //     ],
  //   },
  //   {
  //     Id: "11",
  //     sImg: icon6,
  //     title: "Maintenance and Customer Support",
  //     slug: "Maintenance-and-Customer-Support",
  //     features: [
  //       "CareCraft Maintenance",
  //       "FixItPro Maintenance",
  //       "TechCare Maintenance",
  //       "AssistEdge Maintenance",
  //     ],
  //   },
  //   {
  //     Id: "12",
  //     sImg: icon7,
  //     title: "Strategic Planning and Execution",
  //     slug: "Strategic-Planning-and-Execution",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
  //   {
  //     Id: "13",
  //     sImg: icon8,
  //     title: "Business Process Optimization",
  //     slug: "Business-Process-Optimization",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
  //   {
  //     Id: "14",
  //     sImg: icon9,
  //     title: "Digital Transformation Consulting",
  //     slug: "Digital-Transformation-Consulting",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
  //   {
  //     Id: "15",
  //     sImg: icon10,
  //     title: "Strategic Planning and Execution",
  //     slug: "Strategic-Planning-and-Executions",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
  //   {
  //     Id: "16",
  //     sImg: icon11,
  //     title: "Change Management Solutions",
  //     slug: "Change-Management-Solutions",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
  //   {
  //     Id: "17",
  //     sImg: icon12,
  //     title: "Performance Metrics and KPI Development",
  //     slug: "Performance-Metrics-and-KPI-Development",
  //     description:
  //       "Our consulting services are customized to suit the unique needs and goals of each client, ensuring precise alignment with their business objectives.",
  //   },
];

export default Services;
