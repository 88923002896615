import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/software-company-components/Footer/Footer";
import m1 from "../../images/actual/Manufacturing/1.png";
import m2 from "../../images/actual/Manufacturing/2.png";
import m3 from "../../images/actual/Manufacturing/3.png";
import m11 from "../../images/actual/Manufacturing/sub/1.png";
import m12 from "../../images/actual/Manufacturing/sub/2.png";
import m21 from "../../images/actual/Manufacturing/sub/3.png";
import m22 from "../../images/actual/Manufacturing/sub/4.png";
import m31 from "../../images/actual/Manufacturing/sub/5.png";
import m32 from "../../images/actual/Manufacturing/sub/6.png";
import mbg from "../../images/backgrounds/manu-bg.png";
import down1 from "../../images/actual/Manufacturing/down1.png";
import down2 from "../../images/actual/Manufacturing/down2.png";
import cbanner from "../../images/backgrounds/common-banner.gif";

import "./manufacturing.css";
import { useState } from "react";
// Define the image sets
const imageSets = {
  set1: [`${m12}`, `${m2}`, `${m1}`],
  set2: [`${m22}`, `${m21}`, `${m22}`],
  set3: [`${m3}`, `${m31}`, `${m32}`],
};
const Manufacturing = () => {
  const [currentSet, setCurrentSet] = useState([`${m1}`, `${m11}`, `${m12}`]);

  const handleMouseEnter = (setKey) => {
    setCurrentSet(imageSets[setKey]);
  };

  return (
    <Fragment>
      <div>
        <Header2 />
        <div
          style={{
            width: "100%",
            height: "70vh",
            backgroundImage: `url(${cbanner})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1>Manufacturing</h1>
        </div>

        {/*<div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                 <h5 style={{ paddingBottom: "25px" }}>Main Heading</h5>
                <h2 style={{ paddingBottom: "25px" }}>Sub Heading</h2> 
                <div className="flip-container">
                  <img
                    src="https://dummyjson.com/image/750x500"
                    alt=""
                    className="flip-card"
                  />
                </div>
              </div>
              <div className="col-lg-4 flip-container ">
                <img
                  src="https://dummyjson.com/image/550x500"
                  alt=""
                  style={{ paddingTop: "125px", paddingBottom: "25px" }}
                  className="flip-card1"
                />
                {/* <h5 style={{ paddingBottom: "25px" }}>Any content here</h5> 
              </div>
            </div>
          </div>
        </div>*/}

        <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
          <div className="container">
            <div className="row ">
              {/* <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "50px",
                }}
              >
                We Manufacture
              </h1> */}

              <div className="col-lg-4">
                <div
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onMouseEnter={() => handleMouseEnter("set1")}
                    onClick={() => handleMouseEnter("set1")}
                    style={{
                      marginBottom: "25px",
                      backgroundColor: "#3286C7",
                      color: "white",
                      width: "300px",
                      height: "60px",
                      borderRadius: "15px",
                      fontSize: "1.5rem",
                    }}
                  >
                    VIAL LINE
                  </button>
                </div>
                <div
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onMouseEnter={() => handleMouseEnter("set2")}
                    onClick={() => handleMouseEnter("set2")}
                    style={{
                      marginBottom: "25px",
                      backgroundColor: "#3286C7",
                      color: "white",
                      width: "300px",
                      height: "60px",
                      borderRadius: "15px",
                      fontSize: "1.5rem",
                    }}
                  >
                    AMPOULES
                  </button>
                </div>
                <div
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onMouseEnter={() => handleMouseEnter("set3")}
                    onClick={() => handleMouseEnter("set3")}
                    style={{
                      marginBottom: "25px",
                      backgroundColor: "#3286C7",
                      color: "white",
                      width: "300px",
                      height: "60px",
                      borderRadius: "15px",
                      fontSize: "1.5rem",
                    }}
                  >
                    RESPULES
                  </button>
                </div>
              </div>

              <div
                className="col-lg-3 "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img key={0} src={currentSet[1]} alt="" className="image" />
              </div>
              {/* <div className="col-lg-6">
                <img key={1} src={currentSet[2]} alt="" className="image" />
              </div> */}

              <div className="col-lg-5 ">
                <img
                  key={2}
                  src={currentSet[0]}
                  alt=""
                  className="image"
                  style={{ height: "370px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
          <div className="container">
            {/* <h1
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              Heading if any
            </h1> */}
            <div className="row">
              <div className="col-lg-6">
                <img src={down1} alt="" />
              </div>
              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <div>
                    <h4>Liquid Injections (Glass Vials & Ampoules)</h4>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <span style={{ fontWeight: "bolder" }}>Capacity :</span>{" "}
                        60 million vials per annum
                      </li>
                      <li>
                        <span style={{ fontWeight: "bolder" }}>Details :</span>{" "}
                        This capacity is achieved through the combined output of
                        the first two manufacturing lines, which can handle
                        various fill volumes ranging from 2 ml to 100 ml.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <div>
                    <h4>BFS (Blow Fill Seal) Vials</h4>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <span style={{ fontWeight: "bolder" }}>Capacity:</span>{" "}
                        14 million vials per annum
                      </li>
                      <li>
                        <span style={{ fontWeight: "bolder" }}>Details:</span>{" "}
                        This line is specifically focused on the production of
                        BFS vials, ensuring a streamlined process that enhances
                        productivity and maintains high quality.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src={down2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ width: "90vw", height: "65vh" }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="gmap_canvas ">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15280.402861427956!2d81.1714169!3d16.7716641!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3641d3197d1c3b%3A0x80ca01044129ac08!2sSaanso%20Pharma%20Private%20Limited!5e0!3m2!1sen!2sin!4v1728394133317!5m2!1sen!2sin"
                  style={{ height: "60vh" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <br />

        {/* <div
          className="container"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div className="row">
            <div className="col-lg-4">
              <img src="https://dummyjson.com/image/400x300" alt="" />
              <br />

              <img
                src="https://dummyjson.com/image/400x300"
                alt=""
                style={{ marginTop: "20px" }}
              />
            </div>
            <div className="col-lg-4">
              <img src="https://dummyjson.com/image/400x620" alt="" />
            </div>
            <div className="col-lg-4">
              <img src="https://dummyjson.com/image/400x300" alt="" />
              <br />

              <img
                src="https://dummyjson.com/image/400x300"
                alt=""
                style={{ marginTop: "20px" }}
              />
            </div>
          </div>
        </div> */}

        <Footer />
        <Scrollbar />
      </div>
    </Fragment>
  );
};
export default Manufacturing;
