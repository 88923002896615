import React from "react";
import Bg from "../../images/shapes/bg_pattern_4.svg";

const PageTitle = (props) => {
  return (
    <section
      className="page_banner_section text-center"
      //   style={{ backgroundImage: `url(${Bg})` }}
      style={{ backgroundColor: "white" }}
    >
      <div className="container">
        <h1 className="page_title mb-0 ">{props.pageTitle}</h1>
      </div>
    </section>
  );
};

export default PageTitle;
