import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage2 from "../HomePage2/HomePage2";

import AboutUsPage from "../AboutUsPage/AboutUsPage";

import PortfolioPage from "../PortfolioPage/PortfolioPage";
import PortfolioSinglePage from "../PortfolioSinglePage/PortfolioSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";

import Gallery from "../Gallery/Gallery";
import Careers from "../Careers/Careers";
import Category1 from "../Divisions/Category1";
import Category2 from "../Divisions/Category2";
import Category3 from "../Divisions/Category3";
import Category4 from "../Divisions/Category4";
import ResearchAndDevelopment from "../R&D/ResearchAndDevelopment";
import Manufacturing from "../Manufacturing/Manufacturing";
import FullScreenVideo from "../HomeVideo/HomeVideo";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FullScreenVideo />} />
          <Route path="/home" element={<HomePage2 />} />
          <Route path="cardio" element={<Category1 />} />
          <Route path="neuro" element={<Category2 />} />
          <Route path="respiro" element={<Category3 />} />
          <Route path="pharma" element={<Category4 />} />

          <Route path="/manufacturing" element={<Manufacturing />} />
          <Route
            path="/research-development"
            element={<ResearchAndDevelopment />}
          />
          {/* <Route path="gallery" element={<Gallery />} /> */}
          <Route path="about" element={<AboutUsPage />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<ContactPage />} />
          {/* <Route path="home" element={<HomePage2 />} /> */}

          {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio_details/:slug"
            element={<PortfolioSinglePage />}
          /> */}

          {/* <Route path="blog" element={<BlogPage />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
