import React from "react";
import wImg from "../../images/about/about_image_5.webp";
import sIcon1 from "../../images/icons/icon_check_2.svg";
import sIcon2 from "../../images/icons/icon_leaf.svg";
import sIcon3 from "../../images/icons/icon_box.svg";
import sIcon4 from "../../images/icons/icon_receipt_add.svg";
import why from "../../images/actual/about/Why saanso.png";
import sIcon5 from "../../images/icons/icon_monitor.svg";
import sIcon6 from "../../images/icons/icon_microscope.svg";
import whybg from "../../images/actual/about/why-saanso-background.png";

const Policy = [
  {
    title: "Young Pioneers",
    icon: sIcon1,
  },
  {
    title: "Innovative Solutions",
    icon: sIcon2,
  },
  {
    title: "Commitment to Quality",
    icon: sIcon3,
  },
  {
    title: "Experienced Team",
    icon: sIcon4,
  },
  {
    title: "Customer Focus",
    icon: sIcon5,
  },
  {
    title: "Diverse therapies",
    icon: sIcon6,
  },
];

const WhyUs = (props) => {
  return (
    <section className="service_section section_space bg-light">
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6">
            <div className="image_wrap">
              <img src={why} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ps-lg-5">
              <div className="heading_block">
                <div className="heading_focus_text">
                  <h1>Why Saanso?</h1>
                </div>
                <p className=" mb-0">
                  Saanso is committed to conducting business responsibly and
                  sustainably. We implement green practices across our
                  operations, from reducing waste and emissions to ensuring
                  ethical sourcing of materials.
                </p>
              </div>
              <ul className="service_facilities_group unordered_list">
                {Policy.map((policy, pitem) => (
                  <li key={pitem}>
                    <strong
                      className="iconbox_block layout_icon_left"
                      style={{ backgroundImage: `url(${whybg})` }}
                    >
                      <span className="iconbox_icon">
                        <img src={policy.icon} alt="Dollar SVG Icon" />
                      </span>
                      <span className="iconbox_content">
                        <strong className="iconbox_title mb-0">
                          {policy.title}
                        </strong>
                      </span>
                    </strong>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
