import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import logo from "../../images/logo/logo-white.png";
import logo2 from "../../images/logo/logo.png";
// import cases from "../../images/case/case_image_4.webp";
import MobileMenu from "../MobileMenu/MobileMenu";

const Header2 = (props) => {
  const [mobailActive, setMobailState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="site_header site_header_2">
      <div
        className={`header_bottom stricky  ${
          isSticky ? "stricked-menu stricky-fixed" : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2 col-4">
              <div className="site_logo">
                <Link onClick={ClickHandler} className="site_link" to="/home">
                  <img src={logo2} alt="" style={{ width: "250px" }} />
                  <img src={logo2} alt="" style={{ width: "250px" }} />
                </Link>
              </div>
            </div>
            <div className="col-xl-8 col-lg-9 col-6">
              <nav className="main_menu navbar navbar-expand-lg">
                <div
                  className="main_menu_inner collapse navbar-collapse justify-content-lg-center"
                  id="main_menu_dropdown"
                >
                  <ul className="main_menu_list unordered_list justify-content-center">
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/home"
                        style={{ color: "black" }}
                      >
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/about"
                        style={{ color: "black" }}
                      >
                        ABOUT US
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/research-development"
                        style={{ color: "black" }}
                      >
                        R & D
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/manufacturing"
                        style={{ color: "black" }}
                      >
                        MANUFACTURING
                      </Link>
                    </li>
                    <li className="dropdown">
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="#"
                        id="pages_submenu"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ color: "black" }}
                      >
                        MARKETING
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="pages_submenu"
                      >
                        <li>
                          <Link onClick={ClickHandler} to="/cardio">
                            Cardiovascular Sciences
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/neuro">
                            Neuro Sciences
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/respiro">
                            Respiro Sciences
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/pharma">
                            General Segment
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/gallery">
                        GALLERY
                      </Link>
                    </li> */}

                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/careers"
                        style={{ color: "black" }}
                      >
                        CAREERS
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/contact"
                        style={{ color: "black" }}
                      >
                        CONTACT
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-xl-1 col-lg-1 col-1">
              <ul className="header_btns_group unordered_list justify-content-end">
                <li>
                  <button
                    // className="mobile_menu_btn"
                    onClick={() => setMobailState(!mobailActive)}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_menu_dropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i
                      className="far fa-bars"
                      style={{
                        color: "black",
                        paddingRight: "15px",
                      }}
                    ></i>
                  </button>
                </li>
                <li>
                  {/* <Link
                    onClick={ClickHandler}
                    className="btn btn-primary"
                    to="/pricing"
                  >
                    <span className="btn_label" data-text="Get Started">
                      Get Started
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobail-wrap">
          <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
            <div className="xb-header-menu-scroll">
              <div
                className="xb-menu-close xb-hide-xl xb-close"
                onClick={() => setMobailState(!mobailActive)}
              ></div>
              <nav className="xb-header-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
          <div
            className="xb-header-menu-backdrop"
            onClick={() => setMobailState(false)}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
