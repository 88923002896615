import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from "../../../images/clients/1.png";
import pimg2 from "../../../images/clients/2.png";
import pimg3 from "../../../images/clients/3.png";
import pimg4 from "../../../images/clients/4.png";
import pimg5 from "../../../images/clients/5.png";
import pimg6 from "../../../images/clients/6.png";
import pimg7 from "../../../images/clients/7.png";
import pimg8 from "../../../images/clients/8.png";
import pimg9 from "../../../images/clients/9.png";
import pimg10 from "../../../images/clients/10.png";
import pimg11 from "../../../images/clients/11.png";
import pimg12 from "../../../images/clients/12.png";
import pimg13 from "../../../images/clients/13.png";
import pimg14 from "../../../images/clients/14.png";
import pimg15 from "../../../images/clients/15.png";
import pimg16 from "../../../images/clients/16.png";
import pimg17 from "../../../images/clients/17.png";

const partners = [
  {
    pImg: pimg1,
  },
  {
    pImg: pimg2,
  },
  {
    pImg: pimg3,
  },
  {
    pImg: pimg4,
  },
  {
    pImg: pimg5,
  },
  {
    pImg: pimg6,
  },
  {
    pImg: pimg7,
  },
  {
    pImg: pimg8,
  },
  {
    pImg: pimg9,
  },
  {
    pImg: pimg10,
  },
  {
    pImg: pimg11,
  },
  {
    pImg: pimg12,
  },
  {
    pImg: pimg13,
  },
  {
    pImg: pimg14,
  },
  {
    pImg: pimg15,
  },
  {
    pImg: pimg16,
  },
  {
    pImg: pimg17,
  },
];

var settings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,

  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FeaturePartners = (props) => {
  return (
    <div
      className="feature_partners_section"
      style={{ backgroundColor: "#3286C7" }}
    >
      <div
        className="container position-relative"
        style={{ backgroundColor: "#3286C7" }}
      >
        <div
          className="title_text text-white"
          style={{ backgroundColor: "#3286C7", marginLeft: "75px" }}
        >
          Our Featured Products
        </div>

        <div
          className="client_logo_carousel"
          style={{ backgroundColor: "#3286C7" }}
        >
          <Slider {...settings}>
            {partners.map((partner, pitem) => (
              <div className="client_logo_item" key={pitem}>
                <img src={partner.pImg} alt=" " />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default FeaturePartners;
