import React, { Fragment, useState, useEffect } from "react";
import Header2 from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/software-company-components/Footer/Footer";
import aImg from "../../images/actual/about/section-1-1.png";
import aImg2 from "../../images/actual/about/Section-1-2.png";
import ModalVideo from "react-modal-video";
import PolicySection from "./Policy";
import WhyUs from "./WhyUs";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import presence from "../../images/actual/about/Presence.png";
import mobile from "../../images/actual/about/mobile.png";
import desktop from "../../images/actual/about/desktop.png";

import "./main-aboutus.css";
import TeamSection from "../../components/TeamSection/TeamSection";
import CtaSection from "../../components/CtaSection/CtaSection";

const AboutUsPage = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider devices with width <= 768px as mobile
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Add event listener on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);
  return (
    <Fragment>
      <Header2 />
      <main className="page_content about-page">
        <PageTitle
          pageTitle={"ABOUT US"}
          pagesub={" SAANSO Pharma"}
          pageTop={"About"}
        />
        <section className="intro_about_section section_space bg-light">
          <div className="intro_about_content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="image_wrap">
                    <img src={aImg} alt=" " />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="image_wrap position-relative">
                    <img src={aImg2} alt="" />
                    {/* <button
                      className="video_btn ripple_effect"
                      onClick={() => setOpen(true)}
                    >
                      <span className="btn_icon">
                        <i className="fa-solid fa-play"></i>
                      </span>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="heading_block mb-0">
              <div className="row justify-content-lg-between">
                <div className="col-lg-4">
                  <div className="heading_focus_text">
                    <p style={{ fontSize: "1.1rem" }}>About Saanso</p>

                    {/* <span className="badge bg-secondary text-white">
                      
                    </span> */}
                  </div>
                  <h2 className="heading_text mb-0">
                    We Provide Access to Quality Care
                  </h2>
                </div>
                <div className="col-lg-6">
                  <p className="heading_description mb-0">
                    Saanso specializes in marketing pharmaceutical formulations
                    across various therapeutic areas since 2017. Its subsidiary,
                    Saanso Life Sciences, operates in South India with over 150
                    team members in four divisions. Key areas include Critical
                    Care, Anesthesia, Pain Management, and Cardiovascular
                    treatments. With advanced R&D, state-of-the-art
                    manufacturing, and a growing portfolio, Saanso delivers
                    high-quality, cost-effective generics for domestic and
                    international markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "75px",
                marginBottom: "-80px",
              }}
            >
              OUR TREK
            </h1>
            {isMobile ? (
              <img src={mobile} alt="" />
            ) : (
              <img src={desktop} alt="" />
            )}
          </div>
        </div>

        <PolicySection />
        <FeaturesSection />
        <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    Saanso racing towards PAN India presence
                  </h1>
                  <br />

                  <div>
                    <p
                      style={{
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        fontSize: "1.15rem",
                      }}
                    >
                      Saanso is a vertically integrated pharmaceutical company
                      with operations strategically located across India. With
                      access to cutting-edge therapeutics, We align the
                      3Ps—Prescribers, Patients, and Business Partners to drive
                      our efforts towards achieving Health Equity in global
                      markets.
                    </p>
                    {/* <p
                      style={{
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    ></p> */}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-7"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  <img src={presence} alt="" className="image-zoom" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <WhyUs />
        {/* <TeamSection /> */}

        {/*<CtaSection /> */}
      </main>
      <Footer />
      {/* <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      /> */}
    </Fragment>
  );
};
export default AboutUsPage;
