import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import video from "../../images/video/home-video.mp4";

const FullScreenVideo = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Play the video when the component loads
    const videoElement = videoRef.current;
    videoElement.play();

    // Request full screen on load
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) {
      /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      /* IE/Edge */
      videoElement.msRequestFullscreen();
    }

    // Redirect to homepage after the video ends
    videoElement.onended = () => {
      navigate("/home"); // replace with the route to your home page
    };
  }, [navigate]);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        muted
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 9999,
        }}
        playsInline
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default FullScreenVideo;
